<template>
  <div class="upload_container required err_message_box">
    <p>
      <span class="txt-red txt-regular" v-if="item.required">*</span>
      {{ item.label }}&nbsp;
      <template v-if="item.sampleFile">
        <a
          :href="item.sampleFile"
          class="template_download small"
          download
        >範本下載</a></template>&nbsp;
      <span class="txt-regular" v-show="item.reminder">({{ item.reminder }})</span>
      <template v-if="item.subLabel">
        <br><span class="txt-regular">({{ item.subLabel }})</span>
      </template>
    </p>
    <span class="err_message txt-red small">{{errorMsg}}</span>
    <input
      :id="id"
      :rules="rules"
      type="file"
      :error="error"
      :accept="item.accept"
      @change="test(); resetSubmited(); fileChangeHandler($event);"
    >
    <label :for="id">
      <p class="upload_hint">
        <span v-show="fileName">
          {{ fileName }}
        </span>
        <span v-show="!fileName">
          請選擇檔案
        </span>
      </p>
      <p class="upload_btn">
        <span>選擇檔案</span>
      </p>
    </label>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'FormUpload',
  props: {
    value: {},
    rules: Array,
    item: {},
    fileTitle: {
      type: String,
    },
    hasSubmited: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errorMsg: '',
      id: null,
      fileName: '',
      overSize: false,
    };
  },
  watch: {
    value(v) {
      if (!this.hasSubmited) {
        if (this.overSize) {
          this.errorMsg = '檔案過大!';
        } else {
          this.errorMsg = this.validate(v) ? this.validate(v) : '';
        }
      } else {
        this.fileName = '';
      }
    },
  },
  computed: {
    hasRules() {
      return this.rules !== undefined
        && this.rules !== null
        && this.rules.length > 0;
    },
    error() {
      if (this.hasRules && this.errorMsg !== '') {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),
    resetSubmited() {
      if (this.hasSubmited) {
        this.$emit('update:hasSubmited', false);
        this.$emit('update:fileTitle', '');
      }
    },
    test() {
      if (this.overSize) {
        this.errorMsg = '檔案過大!';
      } else {
        this.errorMsg = this.validate(this.fileName) ? this.validate(this.fileName) : '';
      }
    },
    fileTypeTest(type, accept = this.item.accept) {
      const testArr = [];

      if (accept.indexOf('jpg') !== -1 || accept.indexOf('jpeg') !== -1) {
        testArr.push('image/jpeg');
      }
      if (accept.indexOf('png') !== -1) {
        testArr.push('image/png');
      }
      if (accept.indexOf('pdf') !== -1) {
        testArr.push('application/pdf');
      }
      if (accept.indexOf('zip') !== -1) {
        testArr.push('application/zip');
      }
      if (accept.indexOf('doc') !== -1) {
        testArr.push('application/msword');
      }
      if (accept.indexOf('docx') !== -1) {
        testArr.push('application/vnd.openxmlformats-officedocument.wordprocessingml.document');
      }
      if (accept.indexOf('xls') !== -1) {
        testArr.push('application/vnd.ms-excel');
      }
      if (accept.indexOf('xlsx') !== -1) {
        testArr.push('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      }
      if (accept.indexOf('json') !== -1) {
        testArr.push('application/json');
      }

      if (testArr.indexOf(type) !== -1) {
        return true;
      }
      return false;
    },
    fileChangeHandler(e) {
      const fileType = _.get(e, 'target.files[0].type', null);
      const fileSize = _.get(e, 'target.files[0].size', null);
      const limitSize = _.get(this, 'item.size', null);

      /** 檢查是否有上傳檔案 */
      if (e.target.files.length > 0) {
        /** 檢查檔案類型 */
        if (this.fileTypeTest(fileType)) {
          /** 檢查檔案大小 */
          if (fileSize < limitSize) {
            this.$emit('input', e.target.files[0]);
            this.fileName = e.target.files[0].name;
            this.$emit('update:fileTitle', this.fileName);
            this.overSize = false;
          } else {
            // this.$emit('input', e.target.files[0]);
            // this.fileName = e.target.files[0].name;
            // this.$emit('update:fileTitle', this.fileName);
            this.openModal({
              message: '檔案過大，請重新上傳',
            });
            this.$emit('input', null);
            this.fileName = '';
            this.$emit('update:fileTitle', '');
            this.overSize = true;
          }
        } else {
          this.openModal({
            message: '檔案類型錯誤，請重新上傳',
          });
          this.$emit('input', null);
          this.fileName = '';
          this.$emit('update:fileTitle', '');
        }
      } else {
        this.$emit('input', null);
        this.fileName = '';
        this.$emit('update:fileTitle', '');
        this.overSize = false;
      }
    },
    validate(val = this.fileName) {
      if (this.hasRules === true) {
        const errorMessage = [];
        this.rules.forEach((rule) => {
          if (typeof rule === 'function') {
            if (rule(val) !== true) {
              errorMessage.push(rule(val));
            }
          } else if (typeof rule === 'object') {
            if (!val.match(rule[0])) {
              errorMessage.push(rule[1]);
            }
          }
        });
        if (errorMessage.length) {
          return errorMessage[0];
        }
      }
      return '';
    },
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.id = this._uid;
  },
};
</script>
