<template>
  <label :for="id" class="err_message_box p txt-bold">
    <span class="err_message txt-red small">{{errorMsg}}</span>
    <template v-if="item.routeUrl">
      本人認同本學會之
      <router-link
        :to="item.routeUrl"
        class="txt-light_green txt-bold"
        target="_blank"
      >
        章程
      </router-link>
      ，依據規章，遵守規則，申請加入為台灣居家醫療醫學會會員
    </template>
    <input
      :id="id"
      type="checkbox"
      :value="value"
      :rules="rules"
      :error="error"
      @change="test()"
      @input="$emit('input', $event.target.checked)"
    >
    <span class="checkmark"></span>
  </label>
</template>

<script>
export default {
  name: 'FormCheckbox',
  props: {
    value: {},
    rules: Array,
    item: {},
  },
  data() {
    return {
      errorMsg: '',
      id: null,
    };
  },
  watch: {
    value() {
      if (!this.value) {
        this.errorMsg = '請勾選認同章程始可申請入會';
      }
    },
  },
  computed: {
    error() {
      if (this.errorMsg !== '') {
        return true;
      }
      return false;
    },
  },
  methods: {
    test() {
      this.errorMsg = !this.value ? '請勾選認同章程始可申請入會' : '';
    },
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.id = this._uid;
  },
};
</script>
