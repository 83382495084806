<template>
  <div class="select_container">
    <p>
      <span class="txt-red txt-regular" v-if="required">*</span>
      {{ title }}
    </p>
    <div class="select_container-left err_message_box w50">
      <span class="err_message txt-red small">{{ errorMsg }}</span>
      <select
        v-model="targetCity"
        class="select_city address_top w33"
        :disabled="disabled"
        @change="resetCity(); citySelector(); test();"
      >
        <option value="" disabled selected>請選擇</option>
        <option
          v-for="(town, index) in cityOptions"
          :key="index"
          :value="town"
        >{{ town }}
        </option>
      </select>
      <select
        v-model="targetArea"
        class="select_city address_top w33"
        :disabled="disabled"
        @change="updateZip(); areaSelector()"
      >
        <option value="" disabled selected>請選擇</option>
        <option
          v-for="(area, index) in areaOptions"
          :key="index"
          :value="area"
        >{{ area }}
        </option>
      </select>
      <input
        v-model="targetZipcode"
        type="text"
        readonly
        class="address_top w33 disabled"
        placeholder="郵遞區號"
      >
    </div>
    <div class="select_container-right w50">
      <TextInput
        class="address_top"
        v-model.trim="targetAddr"
        :item="inputFormat.addressDetailInput"
        :rules="[
          val => !!val || '必填欄位！',
        ]"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import TextInput from '@/components/form/TextInput.vue';
import { zipcode } from '@/lib/http';

export default {
  name: 'FormAddress',
  created() {
    zipcode().then((result) => {
      this.cities = result;
    });
    this.targetCity = this.city;
    this.targetArea = this.area;
    this.targetZipcode = this.zipcode;
    this.targetAddr = this.addr;
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.id = this._uid;
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    city: {
      type: String,
      default: '',
    },
    area: {
      type: String,
      default: '',
    },
    zipcode: {
      type: String,
      default: '',
    },
    addr: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cities: [],
      targetCity: '',
      targetArea: '',
      targetZipcode: '',
      targetAddr: '',
      errorMsg: '',
      inputFormat: {
        addressDetailInput: {
          label: '',
          type: 'text',
          placeholder: `請填寫${this.title}`,
          required: this.required,
        },
      },
      childError: false,
    };
  },
  computed: {
    cityOptions() {
      return this.cities.map((city) => city.city);
    },
    areaOptions() {
      const cityIndex = this.cityOptions.indexOf(this.targetCity);
      if (cityIndex >= 0) {
        return this.cities[cityIndex].list.map((area) => area.area);
      }
      return [];
    },
    error() {
      if (this.errorMsg !== '' || this.childError) {
        return true;
      }
      return false;
    },
  },
  methods: {
    citySelector() {
      this.$emit('update:city', this.targetCity);
    },
    areaSelector() {
      this.$emit('update:area', this.targetArea);
    },
    resetCity() {
      this.$emit('update:area', '');
      this.$emit('update:zipcode', '');
    },
    updateZip() {
      const cityIndex = this.cityOptions.indexOf(this.targetCity);
      const areaIndex = this.areaOptions.indexOf(this.targetArea);
      if (areaIndex >= 0) {
        this.$emit('update:zipcode', this.cities[cityIndex].list[areaIndex].zipcode);
      }
      if (areaIndex === -1) {
        this.$emit('update:zipcode', '');
      }
    },
    validate() {
      if (!this.targetCity || !this.targetArea) {
        this.errorMsg = '必選欄位！請選擇縣市及區域！';
      }
      if (this.targetCity && this.targetArea) {
        this.errorMsg = '';
      }
    },
    test() {
      if (this.required) {
        this.validate();
        this.$children.forEach((item) => {
          if (item.$options.name && item.$options.name.match(/^Form/i)) {
            item.test();
            if (item.error) {
              this.childError = true;
            } else {
              this.childError = false;
            }
          }
        });
      }
    },
  },
  watch: {
    city() {
      this.targetCity = this.city;
      this.test();
    },
    area() {
      this.targetArea = this.area;
      this.test();
    },
    zipcode() {
      this.targetZipcode = this.zipcode;
    },
    addr() {
      this.targetAddr = this.addr;
    },
    targetAddr() {
      this.$emit('update:addr', this.targetAddr);
    },
  },
  components: {
    TextInput,
  },
};
</script>
