<template>
  <div class="application">
    <!-- kv -->
    <section
      id="kv_content"
      :style="{ backgroundImage: `url( ${require('@/statics/img/banner/apply.webp')} )` }"
    >
      <div class="kv_title txt-white">
        <h1>APPLICATION</h1>
        <h2>入會申請</h2>
      </div>
    </section>

    <!-- current stage -->
    <section
      id="stage"
      :style="{ backgroundImage: `url( ${require('@/statics/img/index/kv_bg.jpg')} )` }"
    >
      <div class="w1300">

        <!-- sub header -->
        <div id="apply_header">
          <div class="apply_header_left">
            <h3 class="apply_header_title txt-bold">招募對象</h3>
            <div class="img_box">
              <img src="@/statics/img/index/icon_title2.svg" alt="">
            </div>
            <div class="apply_header_content">
              <p>凡認同本學會之章程，依據規章，遵守規則，申請加入的<br>
                <span>台灣居家醫療相關實務工作者</span><br>
                <span>從事居家醫療相關研究者</span><br>
                <span>醫師 (領有醫師證書滿2年)</span>
              </p>
            </div>
          </div>
          <div class="apply_header_right">
            <h3 class="apply_header_title txt-bold">入會費用</h3>
            <div class="img_box">
              <img src="@/statics/img/index/icon_title2.svg" alt="">
            </div>
            <div class="apply_header_content">
              <p>
                入會費 (2000元) 常年會費 (3000元/年，250元/月)<br>
                例如:8月3日申請入會，將收入會費2000元 + 9-12月常年會費1000元，共3000元。<br>
                付款採用 線上信用卡
                <!-- <template> / ATM 轉帳 / 超商代碼。</template> -->
                繳費<br>
                如有任何使用上的疑慮，歡迎您的來電 0988-206590 謝謝。
              </p>
            </div>
          </div>
        </div>

        <ApplicationStage @toggleVerifyModal="toggleVerifyModal" />

      </div>
    </section>

    <main>
      <section id="application_apply">
        <div class="w1000">

          <!-- form area -->
          <div class="form_box">
            <form id="form_application_apply" enctype="multipart/form-data">

              <div class="form_interactive">
                <div class="half">

                  <TextInput
                    class="w50"
                    v-model.trim="user.realname"
                    :item="inputFormat.nameInput"
                    :rules="[
                      val => !!val || '必填欄位！',
                      val => val.length > 1 || '長度錯誤!',
                    ]"
                  />

                  <Radio
                    class="w50"
                    v-model="user.gender"
                    :item="inputFormat.genderRadio"
                  />

                </div>
                <div class="half">
                  <label for="birthday" id="birth" class="w100">
                    <p><span class="txt-red txt-regular">*</span>出生年月日</p>
                    <date-pick
                      class="w100"
                      v-model="user.birthday"
                      :format="'YYYY-MM-DD'"
                      :inputAttributes="{readonly: true}"
                      :selectableYearRange="{from: 1950, to: startYear}"
                    >
                    </date-pick>
                  </label>
                </div>
                <TextInput
                  class="w50"
                  v-model.trim="user.id_number"
                  :item="inputFormat.idInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    [/[A-Za-z0-9]/, '身分證/居留證格式錯誤']
                    // [/^[A-Z][1-2]\d{8}$/, '身分證格式錯誤']
                  ]"
                  :idCheck="true"
                />

                <TextInput
                  class="w50"
                  v-model.trim="user.phone"
                  :item="inputFormat.phoneInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    [/^09\d{8}$/, '手機格式錯誤']
                  ]"
                  :maxlength="10"
                />

                <TextInput
                  class="w50"
                  v-model.trim="user.memo.office_tel"
                  :item="inputFormat.officeTelInput"
                  :maxlength="10"
                />

                <TextInput
                  class="w50"
                  v-model.trim="user.tel"
                  :item="inputFormat.telInput"
                  :maxlength="10"
                />

                <TextInput
                  class="w50"
                  v-model.trim="user.email"
                  :item="inputFormat.emailInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    [emailRegex, '信箱格式錯誤'],
                  ]"
                />

                <TextInput
                  class="w50"
                  v-model.trim="user.memo.line_id"
                  :item="inputFormat.lineIdInput"
                />

                <Address
                  class="w100"
                  :title="'戶籍地址'"
                  :city.sync="user.city"
                  :area.sync="user.area"
                  :zipcode.sync="user.zipcode"
                  :addr.sync="user.addr"
                  :required="true"
                />

                <!-- <div class="select_container w100">
                  <p><span class="txt-red txt-regular">*</span>戶籍地址</p>
                  <div class="select_container-left err_message_box w50">
                    <span class="err_message txt-red small">{{ homeAddressErrorMsg }}</span>
                    <select
                      v-model="user.city"
                      class="select_city address_top w33"
                      @change="resetHomeSelect"
                    >
                      <option value="" disabled selected>請選擇</option>
                      <option
                        v-for="(town, index) in homeTowns"
                        :key="index"
                        :value="town"
                      >{{ town }}
                      </option>
                    </select>
                    <select
                      v-model="user.area"
                      class="select_city address_top w33"
                      @change="updateHomeZip"
                    >
                      <option value="" disabled selected>請選擇</option>
                      <option
                        v-for="(area, index) in homeAreas"
                        :key="index"
                        :value="area"
                      >{{ area }}
                      </option>
                    </select>
                    <input
                      :value="user.zipcode"
                      type="text"
                      readonly
                      class="address_top w33"
                      placeholder="郵遞區號"
                    >
                  </div>
                  <div class="select_container-right w50">
                    <TextInput
                      class="address_top"
                      v-model.trim="user.addr"
                      :item="inputFormat.addressHomeDetailInput"
                      :rules="[
                        val => !!val || '必填欄位！',
                      ]"
                    />
                  </div>
                </div> -->

                <TextInput
                  class="w50"
                  v-model.trim="user.jobs.department"
                  :item="inputFormat.departmentInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                />

                <TextInput
                  class="w50"
                  v-model.trim="user.jobs.hospital_name"
                  :item="inputFormat.hospitalIdInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                />

                <Address
                  class="w100"
                  :title="'院所地址'"
                  :city.sync="user.jobs.hospital_city"
                  :area.sync="user.jobs.hospital_area"
                  :zipcode.sync="user.jobs.hospital_zipcode"
                  :addr.sync="user.jobs.hospital_addr"
                />

                <!-- <div class="select_container w100">
                  <p><span class="txt-red txt-regular star_hidden">*</span>院所地址</p>
                  <div class="select_container-left err_message_box w50">
                    <span class="err_message txt-red small">{{ hospitalAddressErrorMsg }}</span>
                    <select
                      v-model="user.jobs.hospital_city"
                      class="select_city address_top w33"
                      @change="resetHospitalSelect"
                    >
                      <option value="" selected>請選擇</option>
                      <option
                        v-for="(town, index) in hospitalTowns"
                        :key="index"
                        :value="town"
                      >{{ town }}
                      </option>
                    </select>
                    <select
                      v-model="user.jobs.hospital_area"
                      class="select_city address_top w33"
                      @change="updateHospitalZip"
                    >
                      <option value="" selected>請選擇</option>
                      <option
                        v-for="(area, index) in hospitalAreas"
                        :key="index"
                        :value="area"
                      >{{ area }}
                      </option>
                    </select>
                    <input
                      :value="user.jobs.hospital_zipcode"
                      type="text"
                      readonly
                      class="address_top w33"
                      placeholder="郵遞區號"
                    >
                  </div>
                  <div class="select_container-right w50">
                    <TextInput
                      class="address_top"
                      v-model.trim="user.jobs.hospital_addr"
                      :item="inputFormat.addressHospitalDetailInput"
                    />
                  </div>
                </div> -->

                <TextInput
                  class="w100"
                  v-model.trim="user.memo.resume"
                  :item="inputFormat.resumeInput"
                />

                <TextInput
                  class="w50"
                  v-model.trim="user.school.name"
                  :item="inputFormat.schoolNameInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                />

                <div class="select_container w50">
                  <TextInput
                    class="w50"
                    v-model.trim="user.school.graduation"
                    :item="inputFormat.schoolGraduationInput"
                    :rules="[
                      val => !!val || '必填欄位！',
                    ]"
                  />
                  <Select
                    class="w50"
                    v-model="user.memo.license_to_date"
                    :item="inputFormat.licenseToDateSelect"
                    :rules="[
                      val => !!val || '必選欄位！',
                    ]"
                  />
                </div>

                <Upload
                  class="w50"
                  v-model="user.files.id_1.file"
                  :item="inputFormat.id1_upload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                  :fileTitle.sync="user.files.id_1.title"
                />

                <Upload
                  class="w50"
                  v-model="user.files.id_2.file"
                  :item="inputFormat.id2_upload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                  :fileTitle.sync="user.files.id_2.title"
                />

                <Upload
                  class="w50"
                  v-model="user.files.license_1.file"
                  :item="inputFormat.license1_upload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                  :fileTitle.sync="user.files.license_1.title"
                />

                <Upload
                  class="w50"
                  v-model="user.files.license_2.file"
                  :item="inputFormat.license2_upload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                  :fileTitle.sync="user.files.license_2.title"
                />

                <Upload
                  class="w100"
                  v-model="user.files.photo.file"
                  :item="inputFormat.photo_upload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                  :fileTitle.sync="user.files.photo.title"
                />

                <TextInput
                  class="w100"
                  v-model.trim="user.memo.fee_title"
                  :item="inputFormat.feeTitleInput"
                />
              </div>

              <div class="form_submit">
                <Checkbox
                  v-model="user.memo.agreeToFollow"
                  :item="inputFormat.agreeToFollowCheckbox"
                />
                <button
                  type="button"
                  id="send_btn"
                  class="btn"
                  @click="onSubmit"
                >送出</button>
              </div>

            </form>
          </div>

        </div>
      </section>
    </main>

    <!-- lightbox -->
    <div
      id="modal_container-verify"
      class="modal_container"
      :class="{ active: modalVerify }"
      @click="closeModal"
    >
      <div class="modal" @click.stop>
        <div class="close_btn h2" @click="closeModal"><span>×</span></div>
        <form action="" method="post" @submit.prevent>
          <ModalTextInput
            v-model.trim="userVerify.id_number"
            :item="inputFormat.idVerifyInput"
            :rules="[
              [/[A-Za-z0-9]/, '身分證/居留證格式錯誤']
              // [/^[A-Z][1-2]\d{8}$/, '身分證格式錯誤'],
            ]"
          />
          <button
            id="send_btn_verify"
            class="btn"
            type="button"
            @click="verifyModalSubmit"
          >
            送出
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/scss/application.scss';

import ApplicationStage from '@/components/ApplicationStage.vue';
import TextInput from '@/components/form/TextInput.vue';
import ModalTextInput from '@/components/form/ModalTextInput.vue';
import Select from '@/components/form/Select.vue';
import Radio from '@/components/form/Radio.vue';
import Checkbox from '@/components/form/Checkbox.vue';
import Upload from '@/components/form/Upload.vue';
import Address from '@/components/form/Address.vue';

import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';
import _ from 'lodash';

import { mapState, mapActions } from 'vuex';
import {
  registerAsMember,
  idVerify,
  login,
  // zipcode,
} from '@/lib/http';
import { setAxiosHeaders } from '@/boot/axios';
import { isJson } from '@/lib/public';
import { emailRegex } from '@/lib/const';

export default {
  title: '入會申請',
  name: 'ApplicationIndex',
  data() {
    return {
      emailRegex,
      cities: [],
      homeAddressErrorMsg: '',
      hospitalAddressErrorMsg: '',
      modalVerify: false,
      userVerify: {
        id_number: '',
      },
      user: {
        type: 1,
        user_type: 1,
        id_number: '',
        realname: '',
        gender: '',
        birthday: '',
        phone: '',
        tel: '',
        memo: {
          office_tel: '',
          line_id: '',
          resume: '',
          license_to_date: '',
          fee_title: '',
          agreeToFollow: '',
        },
        email: '',
        city: '',
        area: '',
        zipcode: '',
        addr: '',
        jobs: {
          department: '',
          hospital_name: '',
          hospital_city: '',
          hospital_area: '',
          hospital_zipcode: '',
          hospital_addr: '',
        },
        // hospital_id: '',
        school: {
          name: '',
          graduation: '',
        },
        files: {
          id_1: {
            title: '',
            file: null,
          },
          id_2: {
            title: '',
            file: null,
          },
          license_1: {
            title: '',
            file: null,
          },
          license_2: {
            title: '',
            file: null,
          },
          photo: {
            title: '',
            file: null,
          },
        },
      },
      fakeUser: {
        type: 1,
        user_type: 1,
        id_number: 'A123456789',
        realname: '王小明',
        gender: 'm',
        birthday: '1970-01-01',
        phone: '0987654321',
        tel: '0212345678',
        memo: {
          office_tel: '0212345678',
          line_id: 'asdf1234',
          resume: 'sdfuiausfoijas',
          license_to_date: '11-20年',
          fee_title: 'my title',
          agreeToFollow: 'true',
        },
        email: 'test@gmail.com',
        city: '台北市',
        area: '大安區',
        zipcode: '106',
        addr: 'test addr',
        jobs: {
          department: '中醫科',
          hospital_name: '台大醫院',
          hospital_city: '台北市',
          hospital_area: '大安區',
          hospital_zipcode: '106',
          hospital_addr: 'test addr',
        },
        // hospital_id: '台大醫院',
        school: {
          name: '台灣大學',
          graduation: '80',
        },
        files: {
          id_1: {
            title: '',
            file: null,
          },
          id_2: {
            title: '',
            file: null,
          },
          license_1: {
            title: '',
            file: null,
          },
          license_2: {
            title: '',
            file: null,
          },
          photo: {
            title: '',
            file: null,
          },
        },
      },
      inputFormat: {
        nameInput: {
          label: '姓名',
          type: 'text',
          placeholder: '請輸入姓名',
          required: true,
        },
        idInput: {
          label: '身分證字號/居留證號碼',
          type: 'text',
          placeholder: '請輸入身分證字號/居留證號碼',
          required: true,
        },
        phoneInput: {
          label: '手機號碼',
          type: 'text',
          placeholder: '請輸入手機號碼',
          required: true,
        },
        officeTelInput: {
          label: '電話(公)',
          type: 'text',
          placeholder: '0212345678',
          required: false,
        },
        telInput: {
          label: '電話(家)',
          type: 'text',
          placeholder: '0212345678',
          required: false,
        },
        emailInput: {
          label: 'Email',
          type: 'email',
          placeholder: '請輸入完整Email',
          required: true,
        },
        lineIdInput: {
          label: 'LINE ID',
          type: 'text',
          placeholder: '請輸入LINE ID',
          required: false,
        },
        departmentInput: {
          label: '科別',
          type: 'text',
          placeholder: '請輸入完整名稱',
          required: true,
        },
        hospitalIdInput: {
          label: '院所名稱(例如：台灣居家醫療醫學會)',
          type: 'text',
          placeholder: '請輸入完整名稱',
          required: true,
        },
        resumeInput: {
          label: '簡歷',
          type: 'text',
          placeholder: '請輸入職業簡歷',
          required: false,
        },
        schoolNameInput: {
          label: '畢業學校(含科系所)',
          type: 'text',
          placeholder: '請輸入完整名稱',
          required: true,
        },
        schoolGraduationInput: {
          label: '畢業年度(民國)',
          type: 'text',
          placeholder: '80',
          required: true,
        },
        licenseToDateSelect: {
          label: '取得醫師證書，至今幾年',
          placeHolder: '選擇區間',
          options: [
            '0-10年',
            '11-20年',
            '21-30年',
          ],
          required: true,
        },
        feeTitleInput: {
          label: '收據抬頭',
          type: 'text',
          placeholder: '請輸入抬頭',
          required: false,
        },
        // addressHomeDetailInput: {
        //   label: '',
        //   type: 'text',
        //   placeholder: '請填寫戶籍地址',
        //   required: true,
        // },
        // addressHospitalDetailInput: {
        //   label: '',
        //   type: 'text',
        //   placeholder: '請填寫院所地址',
        //   required: false,
        // },
        genderRadio: {
          label: '性別',
          name: 'gender',
          required: true,
          options: [
            {
              name: '男',
              value: 'm',
            },
            {
              name: '女',
              value: 'f',
            },
          ],
        },
        id1_upload: {
          label: '身分證-正面',
          reminder: '格式 jpg/png/pdf。限制大小：1MB',
          accept: '.jpg,.png,.pdf',
          size: 1 * 1024 * 1024,
          required: true,
        },
        id2_upload: {
          label: '身分證-反面',
          reminder: '格式 jpg/png/pdf。限制大小：1MB',
          accept: '.jpg,.png,.pdf',
          size: 1 * 1024 * 1024,
          required: true,
        },
        license1_upload: {
          label: '醫師證書-正面',
          reminder: '格式 jpg/png/pdf。限制大小：1MB',
          accept: '.jpg,.png,.pdf',
          size: 1 * 1024 * 1024,
          required: true,
        },
        license2_upload: {
          label: '醫師證書-反面',
          reminder: '格式 jpg/png/pdf。限制大小：1MB',
          accept: '.jpg,.png,.pdf',
          size: 1 * 1024 * 1024,
          required: true,
        },
        photo_upload: {
          label: '一年內二吋半身照片兩張',
          reminder: '格式 jpg/png/pdf。限制大小：1MB',
          accept: '.jpg,.png,.pdf',
          size: 1 * 1024 * 1024,
          required: true,
        },
        agreeToFollowCheckbox: {
          routeUrl: '/about/association',
        },
        idVerifyInput: {
          label: '請輸入您的身分證字號/居留證號碼',
          type: 'text',
          placeholder: 'A123456789',
          required: false,
        },
      },
    };
  },
  created() {
    // zipcode().then((result) => {
    //   this.cities = result;
    // });
    // this.user = this.fakeUser;
  },
  computed: {
    ...mapState([
      'applyStatus',
    ]),
    startYear() {
      return new Date().getFullYear();
    },
    // homeTowns() {
    //   return this.cities.map((city) => city.city);
    // },
    // homeAreas() {
    //   const homeTownIndex = this.homeTowns.indexOf(this.user.city);
    //   if (homeTownIndex >= 0) {
    //     return this.cities[homeTownIndex].list.map((area) => area.area);
    //   }
    //   return [];
    // },
    // hospitalTowns() {
    //   return this.cities.map((city) => city.city);
    // },
    // hospitalAreas() {
    //   const hospitalTownIndex = this.hospitalTowns.indexOf(this.user.jobs.hospital_city);
    //   if (hospitalTownIndex >= 0) {
    //     return this.cities[hospitalTownIndex].list.map((area) => area.area);
    //   }
    //   return [];
    // },
  },
  methods: {
    ...mapActions([
      'login',
      'logout',
      'openModal',
    ]),
    toggleVerifyModal() {
      this.modalVerify = !this.modalVerify;
    },
    closeModal() {
      this.modalVerify = false;
    },
    // updateHomeZip() {
    //   const homeTownIndex = this.homeTowns.indexOf(this.user.city);
    //   const homeAreaIndex = this.homeAreas.indexOf(this.user.area);
    //   if (homeAreaIndex >= 0) {
    //     this.user.zipcode = this.cities[homeTownIndex].list[homeAreaIndex].zipcode;
    //   }
    //   if (homeAreaIndex === -1) {
    //     this.user.zipcode = '';
    //   }
    //   this.addressHomeTest();
    // },
    // updateHospitalZip() {
    //   const hospitalTownIndex = this.hospitalTowns.indexOf(this.user.jobs.hospital_city);
    //   const hospitalAreaIndex = this.hospitalAreas.indexOf(this.user.jobs.hospital_area);
    //   if (hospitalAreaIndex >= 0) {
    //     this.user.jobs.hospital_zipcode = this.cities[hospitalTownIndex]
    //       .list[hospitalAreaIndex].zipcode;
    //   }
    //   if (hospitalAreaIndex === -1) {
    //     this.user.jobs.hospital_zipcode = '';
    //   }
    //   // this.addressHospitalTest();
    // },
    // resetHomeSelect() {
    //   this.user.area = '';
    //   this.user.zipcode = '';
    //   this.addressHomeTest();
    // },
    // resetHospitalSelect() {
    //   this.user.jobs.hospital_area = '';
    //   this.user.jobs.hospital_zipcode = '';
    //   // this.addressHospitalTest();
    // },
    // addressHomeTest() {
    //   if (!this.user.city || !this.user.area) {
    //     this.homeAddressErrorMsg = '必選欄位！請選擇縣市及區域！';
    //   }
    //   if (this.user.city && this.user.area) {
    //     this.homeAddressErrorMsg = '';
    //   }
    // },
    // addressHospitalTest() {
    //   if (!this.user.jobs.hospital_city || !this.user.jobs.hospital_area) {
    //     this.hospitalAddressErrorMsg = '必選欄位！請選擇縣市及區域！';
    //   }
    //   if (this.user.jobs.hospital_city && this.user.jobs.hospital_area) {
    //     this.hospitalAddressErrorMsg = '';
    //   }
    // },
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      // this.addressHomeTest();
      // if (this.homeAddressErrorMsg !== '') {
      //   errorList.push(true);
      // }
      if (errorList.indexOf(true) === -1) {
        idVerify({ id_number: this.user.id_number }).then((res) => {
          const userApply = _.get(res, 'apply', undefined);
          const userLevel = _.get(res, 'level', undefined);

          let userApplyReason = _.get(res, 'memo.apply', '');
          if (userApplyReason) {
            userApplyReason = `(${userApplyReason})`;
          }

          if (!res || userApply === null) {
            this.logout().then(() => {
              registerAsMember(this.user).then((result) => {
                if (result.data.status) {
                  login({
                    id_number: this.user.id_number,
                    password: this.user.id_number,
                  }).then((response) => {
                    this.login(response.data.result.user);
                    setAxiosHeaders(this.$cookies.get('token'));
                  });
                  this.openModal({
                    message: '已收到您的入會申請<br><br>請至 <span class="txt-light_green">審核狀態</span> 查詢審核結果',
                  });
                } else {
                  // console.log(isJson(result.data.message));
                  // eslint-disable-next-line no-lonely-if
                  if (isJson(result.data.message)) {
                    this.openModal({
                      message: JSON.parse(result.data.message).id_number.error,
                    });
                  } else {
                    this.openModal({
                      message: result.data.message,
                    });
                  }
                }
              });
            });
          } else if (parseInt(userLevel, 10) === 1 || parseInt(userLevel, 10) === 2) {
            this.openModal({
              message: '您已成為本會會員，請直接前往登入頁面進行登入',
              btnLink: '/login',
            });
          } else if (userApply === 1) {
            this.openModal({
              message: `
              入會申請未通過<br>
              ${userApplyReason}<br><br>
              詳情請洽學會<br>
              聯絡電話：0988-206590<br>
              電子郵件：hmca.tw@gmail.com
              `,
            });
          } else {
            this.openModal({
              message: '您已送出申請資料，請勿重複申請入會',
            });
          }
        });

        // const userLevel = this.$cookies.get('level') || '';
        // if (parseInt(userLevel, 10) === 1 || parseInt(userLevel, 10) === 2) {
        //   this.openModal({
        //     message: '您已是本會會員，請勿重複申請入會',
        //   });
        // } else {
        //   registerAsMember(this.user).then((result) => {
        //     if (result.data.status) {
        //       login({
        //         id_number: this.user.id_number,
        //         password: this.user.id_number,
        //       }).then((res) => {
        //         this.login(res.data.result.user);
        //         setAxiosHeaders(this.$cookies.get('token'));
        //       });
        //       this.openModal({
        //         message: '已收到您的入會申請<br><br>請至 <span class="txt-light_green">審核狀態</span> 查詢審核結果',
        //       });
        //     } else {
        //       console.log(isJson(result.data.message));
        //       if (isJson(result.data.message)) {
        //         this.openModal({
        //           message: JSON.parse(result.data.message).id_number.error,
        //         });
        //       } else {
        //         this.openModal({
        //           message: result.data.message,
        //         });
        //       }
        //     }
        //   });
        // }
      } else {
        this.openModal({
          message: '必填欄位尚未完成！',
        });
      }
    },
    /**
     * 審核狀態查詢
     */
    verifyModalSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Modal/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        /** 查詢時，先將目前使用者登出 */
        this.logout().then(() => {
          setAxiosHeaders(null);
        });

        /** 確認使用者資訊並導向不同頁面 */
        idVerify(this.userVerify).then((result) => {
          if (result) {
            const userApply = parseInt(_.get(result, 'apply', null), 10);
            const userLevel = parseInt(_.get(result, 'level', null), 10);

            /** 本會會員(專科/一般): 自行前往登入頁面輸入帳號密碼登入 */
            if (userLevel === 1 || userLevel === 2) {
              this.$cookies.set('realname', result.realname);
              this.$router.push({ path: '/application/success' });

            /** 非會員醫師: 預先做非會員登入，方便後續頁面資料顯示 */
            } else if (userLevel === 3) {
              login({
                id_number: this.userVerify.id_number,
                password: this.userVerify.id_number,
              }).then((res) => {
                this.login(res.data.result.user);
                setAxiosHeaders(this.$cookies.get('token'));

                /** 審核通過前往付款 */
                if (userApply === 2) {
                  this.$router.push({ path: '/application/payment' });

                /** 拒絕入會 */
                } else if (userApply === 1) {
                  this.$router.push({ path: '/application/verify' });

                  /** 後台訊息 */
                  // const applyMsg = _.get(result, 'memo.apply', null);
                  // if (applyMsg) {
                  //   this.openModal({
                  //     message: applyMsg,
                  //   });

                  /** 預設訊息 */
                  // } else {
                  //   this.openModal({
                  //     message: '入會資格不符，詳情請洽學會',
                  //   });
                  // }

                /** 審核中 或 補件 */
                } else if (userApply === 0 || userApply === 9) {
                  this.$router.push({ path: '/application/verify' });

                /** 預設警訊 */
                } else {
                  this.openModal({
                    message: '查無使用者資料',
                  });
                }
              });

            /** 非會員護理人員 */
            } else if (userLevel === 4) {
              login({
                id_number: this.userVerify.id_number,
                password: this.userVerify.id_number,
              }).then((res) => {
                this.login(res.data.result.user);
                setAxiosHeaders(this.$cookies.get('token'));
              });
              this.openModal({
                message: '入會資格不符，詳情請洽學會',
              });

            /** 其他 */
            } else {
              this.openModal({
                message: '您尚未申請入會',
              });
            }
          } else {
            this.openModal({
              message: '您尚未申請入會',
            });
          }
        });
      }
    },
  },
  components: {
    ApplicationStage,
    TextInput,
    ModalTextInput,
    Select,
    Radio,
    Checkbox,
    Upload,
    Address,
    DatePick,
  },
};
</script>
