var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"application"},[_c('section',{style:({ backgroundImage: ("url( " + (require('@/statics/img/banner/apply.webp')) + " )") }),attrs:{"id":"kv_content"}},[_vm._m(0)]),_c('section',{style:({ backgroundImage: ("url( " + (require('@/statics/img/index/kv_bg.jpg')) + " )") }),attrs:{"id":"stage"}},[_c('div',{staticClass:"w1300"},[_vm._m(1),_c('ApplicationStage',{on:{"toggleVerifyModal":_vm.toggleVerifyModal}})],1)]),_c('main',[_c('section',{attrs:{"id":"application_apply"}},[_c('div',{staticClass:"w1000"},[_c('div',{staticClass:"form_box"},[_c('form',{attrs:{"id":"form_application_apply","enctype":"multipart/form-data"}},[_c('div',{staticClass:"form_interactive"},[_c('div',{staticClass:"half"},[_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.nameInput,"rules":[
                    function (val) { return !!val || '必填欄位！'; },
                    function (val) { return val.length > 1 || '長度錯誤!'; } ]},model:{value:(_vm.user.realname),callback:function ($$v) {_vm.$set(_vm.user, "realname", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.realname"}}),_c('Radio',{staticClass:"w50",attrs:{"item":_vm.inputFormat.genderRadio},model:{value:(_vm.user.gender),callback:function ($$v) {_vm.$set(_vm.user, "gender", $$v)},expression:"user.gender"}})],1),_c('div',{staticClass:"half"},[_c('label',{staticClass:"w100",attrs:{"for":"birthday","id":"birth"}},[_vm._m(2),_c('date-pick',{staticClass:"w100",attrs:{"format":'YYYY-MM-DD',"inputAttributes":{readonly: true},"selectableYearRange":{from: 1950, to: _vm.startYear}},model:{value:(_vm.user.birthday),callback:function ($$v) {_vm.$set(_vm.user, "birthday", $$v)},expression:"user.birthday"}})],1)]),_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.idInput,"rules":[
                  function (val) { return !!val || '必填欄位！'; },
                  [/[A-Za-z0-9]/, '身分證/居留證格式錯誤'] ],"idCheck":true},model:{value:(_vm.user.id_number),callback:function ($$v) {_vm.$set(_vm.user, "id_number", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.id_number"}}),_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.phoneInput,"rules":[
                  function (val) { return !!val || '必填欄位！'; },
                  [/^09\d{8}$/, '手機格式錯誤']
                ],"maxlength":10},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.phone"}}),_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.officeTelInput,"maxlength":10},model:{value:(_vm.user.memo.office_tel),callback:function ($$v) {_vm.$set(_vm.user.memo, "office_tel", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.memo.office_tel"}}),_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.telInput,"maxlength":10},model:{value:(_vm.user.tel),callback:function ($$v) {_vm.$set(_vm.user, "tel", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.tel"}}),_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.emailInput,"rules":[
                  function (val) { return !!val || '必填欄位！'; },
                  [_vm.emailRegex, '信箱格式錯誤'] ]},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.email"}}),_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.lineIdInput},model:{value:(_vm.user.memo.line_id),callback:function ($$v) {_vm.$set(_vm.user.memo, "line_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.memo.line_id"}}),_c('Address',{staticClass:"w100",attrs:{"title":'戶籍地址',"city":_vm.user.city,"area":_vm.user.area,"zipcode":_vm.user.zipcode,"addr":_vm.user.addr,"required":true},on:{"update:city":function($event){return _vm.$set(_vm.user, "city", $event)},"update:area":function($event){return _vm.$set(_vm.user, "area", $event)},"update:zipcode":function($event){return _vm.$set(_vm.user, "zipcode", $event)},"update:addr":function($event){return _vm.$set(_vm.user, "addr", $event)}}}),_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.departmentInput,"rules":[
                  function (val) { return !!val || '必填欄位！'; },
                  function (val) { return val.length > 1 || '長度錯誤!'; } ]},model:{value:(_vm.user.jobs.department),callback:function ($$v) {_vm.$set(_vm.user.jobs, "department", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.jobs.department"}}),_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.hospitalIdInput,"rules":[
                  function (val) { return !!val || '必填欄位！'; },
                  function (val) { return val.length > 1 || '長度錯誤!'; } ]},model:{value:(_vm.user.jobs.hospital_name),callback:function ($$v) {_vm.$set(_vm.user.jobs, "hospital_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.jobs.hospital_name"}}),_c('Address',{staticClass:"w100",attrs:{"title":'院所地址',"city":_vm.user.jobs.hospital_city,"area":_vm.user.jobs.hospital_area,"zipcode":_vm.user.jobs.hospital_zipcode,"addr":_vm.user.jobs.hospital_addr},on:{"update:city":function($event){return _vm.$set(_vm.user.jobs, "hospital_city", $event)},"update:area":function($event){return _vm.$set(_vm.user.jobs, "hospital_area", $event)},"update:zipcode":function($event){return _vm.$set(_vm.user.jobs, "hospital_zipcode", $event)},"update:addr":function($event){return _vm.$set(_vm.user.jobs, "hospital_addr", $event)}}}),_c('TextInput',{staticClass:"w100",attrs:{"item":_vm.inputFormat.resumeInput},model:{value:(_vm.user.memo.resume),callback:function ($$v) {_vm.$set(_vm.user.memo, "resume", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.memo.resume"}}),_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.schoolNameInput,"rules":[
                  function (val) { return !!val || '必填欄位！'; },
                  function (val) { return val.length > 1 || '長度錯誤!'; } ]},model:{value:(_vm.user.school.name),callback:function ($$v) {_vm.$set(_vm.user.school, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.school.name"}}),_c('div',{staticClass:"select_container w50"},[_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.schoolGraduationInput,"rules":[
                    function (val) { return !!val || '必填欄位！'; } ]},model:{value:(_vm.user.school.graduation),callback:function ($$v) {_vm.$set(_vm.user.school, "graduation", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.school.graduation"}}),_c('Select',{staticClass:"w50",attrs:{"item":_vm.inputFormat.licenseToDateSelect,"rules":[
                    function (val) { return !!val || '必選欄位！'; } ]},model:{value:(_vm.user.memo.license_to_date),callback:function ($$v) {_vm.$set(_vm.user.memo, "license_to_date", $$v)},expression:"user.memo.license_to_date"}})],1),_c('Upload',{staticClass:"w50",attrs:{"item":_vm.inputFormat.id1_upload,"rules":[
                  function (val) { return !!val || '請上傳檔案!'; } ],"fileTitle":_vm.user.files.id_1.title},on:{"update:fileTitle":function($event){return _vm.$set(_vm.user.files.id_1, "title", $event)},"update:file-title":function($event){return _vm.$set(_vm.user.files.id_1, "title", $event)}},model:{value:(_vm.user.files.id_1.file),callback:function ($$v) {_vm.$set(_vm.user.files.id_1, "file", $$v)},expression:"user.files.id_1.file"}}),_c('Upload',{staticClass:"w50",attrs:{"item":_vm.inputFormat.id2_upload,"rules":[
                  function (val) { return !!val || '請上傳檔案!'; } ],"fileTitle":_vm.user.files.id_2.title},on:{"update:fileTitle":function($event){return _vm.$set(_vm.user.files.id_2, "title", $event)},"update:file-title":function($event){return _vm.$set(_vm.user.files.id_2, "title", $event)}},model:{value:(_vm.user.files.id_2.file),callback:function ($$v) {_vm.$set(_vm.user.files.id_2, "file", $$v)},expression:"user.files.id_2.file"}}),_c('Upload',{staticClass:"w50",attrs:{"item":_vm.inputFormat.license1_upload,"rules":[
                  function (val) { return !!val || '請上傳檔案!'; } ],"fileTitle":_vm.user.files.license_1.title},on:{"update:fileTitle":function($event){return _vm.$set(_vm.user.files.license_1, "title", $event)},"update:file-title":function($event){return _vm.$set(_vm.user.files.license_1, "title", $event)}},model:{value:(_vm.user.files.license_1.file),callback:function ($$v) {_vm.$set(_vm.user.files.license_1, "file", $$v)},expression:"user.files.license_1.file"}}),_c('Upload',{staticClass:"w50",attrs:{"item":_vm.inputFormat.license2_upload,"rules":[
                  function (val) { return !!val || '請上傳檔案!'; } ],"fileTitle":_vm.user.files.license_2.title},on:{"update:fileTitle":function($event){return _vm.$set(_vm.user.files.license_2, "title", $event)},"update:file-title":function($event){return _vm.$set(_vm.user.files.license_2, "title", $event)}},model:{value:(_vm.user.files.license_2.file),callback:function ($$v) {_vm.$set(_vm.user.files.license_2, "file", $$v)},expression:"user.files.license_2.file"}}),_c('Upload',{staticClass:"w100",attrs:{"item":_vm.inputFormat.photo_upload,"rules":[
                  function (val) { return !!val || '請上傳檔案!'; } ],"fileTitle":_vm.user.files.photo.title},on:{"update:fileTitle":function($event){return _vm.$set(_vm.user.files.photo, "title", $event)},"update:file-title":function($event){return _vm.$set(_vm.user.files.photo, "title", $event)}},model:{value:(_vm.user.files.photo.file),callback:function ($$v) {_vm.$set(_vm.user.files.photo, "file", $$v)},expression:"user.files.photo.file"}}),_c('TextInput',{staticClass:"w100",attrs:{"item":_vm.inputFormat.feeTitleInput},model:{value:(_vm.user.memo.fee_title),callback:function ($$v) {_vm.$set(_vm.user.memo, "fee_title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.memo.fee_title"}})],1),_c('div',{staticClass:"form_submit"},[_c('Checkbox',{attrs:{"item":_vm.inputFormat.agreeToFollowCheckbox},model:{value:(_vm.user.memo.agreeToFollow),callback:function ($$v) {_vm.$set(_vm.user.memo, "agreeToFollow", $$v)},expression:"user.memo.agreeToFollow"}}),_c('button',{staticClass:"btn",attrs:{"type":"button","id":"send_btn"},on:{"click":_vm.onSubmit}},[_vm._v("送出")])],1)])])])])]),_c('div',{staticClass:"modal_container",class:{ active: _vm.modalVerify },attrs:{"id":"modal_container-verify"},on:{"click":_vm.closeModal}},[_c('div',{staticClass:"modal",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"close_btn h2",on:{"click":_vm.closeModal}},[_c('span',[_vm._v("×")])]),_c('form',{attrs:{"action":"","method":"post"},on:{"submit":function($event){$event.preventDefault();}}},[_c('ModalTextInput',{attrs:{"item":_vm.inputFormat.idVerifyInput,"rules":[
            [/[A-Za-z0-9]/, '身分證/居留證格式錯誤'] ]},model:{value:(_vm.userVerify.id_number),callback:function ($$v) {_vm.$set(_vm.userVerify, "id_number", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userVerify.id_number"}}),_c('button',{staticClass:"btn",attrs:{"id":"send_btn_verify","type":"button"},on:{"click":_vm.verifyModalSubmit}},[_vm._v(" 送出 ")])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kv_title txt-white"},[_c('h1',[_vm._v("APPLICATION")]),_c('h2',[_vm._v("入會申請")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"apply_header"}},[_c('div',{staticClass:"apply_header_left"},[_c('h3',{staticClass:"apply_header_title txt-bold"},[_vm._v("招募對象")]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":require("@/statics/img/index/icon_title2.svg"),"alt":""}})]),_c('div',{staticClass:"apply_header_content"},[_c('p',[_vm._v("凡認同本學會之章程，依據規章，遵守規則，申請加入的"),_c('br'),_c('span',[_vm._v("台灣居家醫療相關實務工作者")]),_c('br'),_c('span',[_vm._v("從事居家醫療相關研究者")]),_c('br'),_c('span',[_vm._v("醫師 (領有醫師證書滿2年)")])])])]),_c('div',{staticClass:"apply_header_right"},[_c('h3',{staticClass:"apply_header_title txt-bold"},[_vm._v("入會費用")]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":require("@/statics/img/index/icon_title2.svg"),"alt":""}})]),_c('div',{staticClass:"apply_header_content"},[_c('p',[_vm._v(" 入會費 (2000元) 常年會費 (3000元/年，250元/月)"),_c('br'),_vm._v(" 例如:8月3日申請入會，將收入會費2000元 + 9-12月常年會費1000元，共3000元。"),_c('br'),_vm._v(" 付款採用 線上信用卡 "),_vm._v(" 繳費"),_c('br'),_vm._v(" 如有任何使用上的疑慮，歡迎您的來電 0988-206590 謝謝。 ")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',{staticClass:"txt-red txt-regular"},[_vm._v("*")]),_vm._v("出生年月日")])}]

export { render, staticRenderFns }